import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaPaw, FaUserShield, FaBook, FaUsers } from 'react-icons/fa';

const About = () => {
  useEffect(() => {
    document.title = 'Leash - About Us';
  }, []);

  return (
    <div className="min-h-screen bg-mint-cream">
      {/* Hero Section */}
      <section className="relative py-20 bg-risd-blue text-white">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h1 className="text-5xl font-['Brodien_Bold'] mb-6">Who We Are</h1>
            <p className="text-xl font-['Gully_CD_Light'] leading-relaxed opacity-90">
              LEASH is more than a platform; it's a mission to transform pet care in Egypt. 
              Founded in 2024, LEASH bridges the gap between pet owners and service providers 
              by offering an ethical and community-driven approach to responsible pet ownership.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Story Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="text-center mb-16"
            >
              <h2 className="text-4xl font-['Brodien_Bold'] text-risd-blue mb-6">Our Story</h2>
              <p className="text-lg text-gray-700 leading-relaxed">
                What began as a matchmaking solution for pet breeding has grown into a comprehensive 
                platform catering to all pet care needs. From ethical breeding practices to seamless 
                booking systems and a vibrant community, LEASH has revolutionized the pet ownership 
                experience in Egypt.
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                className="bg-mint-cream p-8 rounded-lg"
              >
                <h3 className="text-2xl font-['Brodien_Bold'] text-risd-blue mb-4">Our Mission</h3>
                <p className="text-gray-700">
                  To empower pet owners by simplifying pet care, fostering connections, and promoting 
                  responsible breeding practices while ensuring pet well-being.
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                className="bg-mint-cream p-8 rounded-lg"
              >
                <h3 className="text-2xl font-['Brodien_Bold'] text-risd-blue mb-4">Our Vision</h3>
                <p className="text-gray-700">
                  To become the MENA region's leading platform for pet care, setting a new standard 
                  for ethical and community-centered services.
                </p>
                <img
            src="/images/positions/Vista Positions-20.png"
            alt="illustration"
            className="absolute right-10 top-12 w-[300px]"
          />
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* What We Offer Section */}
      <section className="py-20 bg-mint-cream">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-['Brodien_Bold'] text-risd-blue text-center mb-16"
          >
            What We Offer
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: <FaPaw />,
                title: "Safe Breeding Solutions",
                description: "Ethical and transparent matchmaking for responsible pet owners."
              },
              {
                icon: <FaUserShield />,
                title: "Trusted Service Providers",
                description: "A curated list of verified groomers, vets, and boarders."
              },
              {
                icon: <FaBook />,
                title: "Educational Resources",
                description: "Expert-backed advice and updates tailored for Egyptian pet owners."
              },
              {
                icon: <FaUsers />,
                title: "Community Connection",
                description: "WhatsApp groups and events that unite pet lovers across the country."
              }
            ].map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white p-6 rounded-lg shadow-md text-center"
              >
                <div className="text-4xl text-risd-blue mb-4">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-['Brodien_Bold'] text-risd-blue text-center mb-16"
          >
            Meet the Team
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            {[
              { name: "Malika Khalil", role: "Founder" },
              { name: "Nour Kenawi", role: "Co-Founder" },
              { name: "Radwa Hassan", role: "Marketing Head" }
            ].map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="text-center p-6 bg-mint-cream rounded-lg"
              >
                <div className="w-24 h-24 bg-risd-blue rounded-full mx-auto mb-4 flex items-center justify-center">
                  <span className="text-white text-2xl">{member.name[0]}</span>
                </div>
                <h3 className="text-xl font-semibold mb-1">{member.name}</h3>
                <p className="text-gray-600">{member.role}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Milestones Section */}
      <section className="py-20 bg-risd-blue text-white">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-['Brodien_Bold'] text-center mb-16"
          >
            Milestones
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            {[
              "Launched WhatsApp community with over 100+ members overnight.",
              "Grew Instagram reach to 70,000+ users in one month.",
              "Successfully conducted the first beta test of the LEASH app."
            ].map((milestone, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="text-center p-6 bg-white/10 rounded-lg backdrop-blur-sm"
              >
                <p className="text-lg">{milestone}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Why LEASH Section */}
      <section className="py-20 bg-mint-cream relative">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h2 className="text-4xl font-['Brodien_Bold'] text-risd-blue mb-8">Why LEASH?</h2>
            <div className="space-y-4">
              {[
                "First of its kind in Egypt.",
                "Focus on ethical practices and expert insights.",
                "Building a supportive pet owner network."
              ].map((point, index) => (
                <div key={index} className="p-4 bg-white rounded-lg shadow-sm">
                  <p className="text-gray-700">{point}</p>
                </div>
              ))}
              <img
            src="/images/positions/Maya positions-04.png"
            alt="illustration"
            className="absolute left-0 top-14 w-[400px]"
          />
            </div>
          </motion.div>
        </div>
      </section>

      {/* Join Us Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h2 className="text-4xl font-['Brodien_Bold'] text-risd-blue mb-6">Join Us</h2>
            <p className="text-xl text-gray-700 mb-8">
              Be a part of the movement transforming pet care. Explore our services and connect 
              with like-minded pet enthusiasts.
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default About;
