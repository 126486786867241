import React, { useState } from 'react';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "What are the basic rules for using Leash?",
      answer: "• Stay respectful and avoid offensive language\n• Keep discussions relevant to pet breeding\n• Share accurate and transparent information about your pet\n• Arrange safe and mutually agreed-upon meetings\n• Do not spam, advertise, or sell pets"
    },
    {
      question: "How can I ensure my pet is safe for breeding?",
      answer: "Make sure your pet is:\n• Fully vaccinated\n• Free from illnesses or health conditions\n• Cleared for breeding by a vet, especially for certain breeds prone to genetic issues"
    },
    {
      question: "What information should I share about my pet?",
      answer: "Share details such as:\n• Breed and age\n• Health status and vaccination records\n• Temperament and behavioral traits\n• Breeding history, if applicable"
    },
    {
      question: "What should I do before arranging a breeding meeting?",
      answer: "• You will be verified by Leash team that the other pet is healthy and vaccinated\n• Agree on terms, such as meeting location and time\n• Always accompany your pet during the meeting"
    },
    {
      question: "Where should I arrange to meet?",
      answer: "Meet in a safe, neutral location such as a park, vet clinic, or other pet-friendly spaces. Avoid meeting at private residences unless both parties are comfortable and trust has been established."
    },
    {
      question: "What should I do during the meeting?",
      answer: "• Monitor the interaction between the pets closely\n• Ensure that both pets are comfortable and not stressed\n• Discuss any next steps with the other owner"
    },
    {
      question: "Is there a fee for breeding arrangements?",
      answer: "Yes, the standard fee for breeding arrangements is 1,000 EGP, split equally between both pet owners."
    },
    {
      question: "How is the payment managed?",
      answer: "Each pet owner contributes 500 EGP to ensure the meeting goes through."
    },
    {
      question: "What happens if the meeting does not go as planned?",
      answer: "Please note that once the payment has been completed, refunds are not available for either party. We recommend carefully reviewing all details and ensuring agreement before finalizing any transactions."
    },
    {
      question: "What should I do if someone behaves inappropriately?",
      answer: "Report any inappropriate behavior to our support team. Include screenshots or details if necessary."
    },
    {
      question: "What if I suspect someone is not being honest about their pet?",
      answer: "Politely ask for additional information, such as health records or vet certifications. If you're still unsure, avoid proceeding with any arrangements and report your concerns to our support team."
    }
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="min-h-screen pt-24 pb-16 px-4 sm:px-6 lg:px-8 bg-mint-cream">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold text-risd-blue mb-8 text-center">Frequently Asked Questions</h1>
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div 
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <button
                className="w-full px-6 py-4 text-left flex justify-between items-center focus:outline-none"
                onClick={() => toggleAccordion(index)}
              >
                <span className="font-medium text-risd-blue">{faq.question}</span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    openIndex === index ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openIndex === index && (
                <div className="px-6 py-4 bg-gray-50">
                  <p className="text-gray-700 whitespace-pre-line">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
