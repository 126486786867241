// frontend/src/pages/Home.js

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaDog, FaHeart, FaUsers, FaComments } from "react-icons/fa";

const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 },
};

const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const Home = () => {
  useEffect(() => {
    document.title = "Leash - Home";
  }, []);

  return (
    <div className="min-h-screen bg-mint-cream">
      {/* Hero Section */}
      <section className="relative h-[90vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 bg-risd-blue opacity-90"></div>
        <div className="absolute bottom-10 right-20 w-full md:w-1/2 lg:w-1/3 max-w-md opacity-100">
          <img
            src="/images/positions/Maya Positions-01.png"
            alt="Clear Mascot"
            className="w-full h-auto"
          />
        </div>
        <motion.div
          className="container mx-auto px-6 relative z-10"
          initial="initial"
          animate="animate"
          variants={staggerContainer}
        >
          <div className="max-w-4xl">
            <motion.h1
              className="text-6xl md:text-7xl font-['Brodien_Bold'] text-white mb-8 leading-tight"
              variants={fadeInUp}
            >
              Welcome to LEASH
            </motion.h1>
            <motion.p
              className="text-2xl md:text-3xl font-['Gully_CD_Light'] text-white/90 mb-6 leading-relaxed"
              variants={fadeInUp}
            >
              Transforming Pet Care and Responsible Breeding in Egypt
            </motion.p>
            <motion.p
              className="text-xl md:text-2xl font-['Gully_CD_Light'] text-white/80 mb-10 leading-relaxed"
              variants={fadeInUp}
            >
              Empowering pet owners with trusted services, a vibrant community,
              and ethical breeding solutions
            </motion.p>
          </div>
        </motion.div>
      </section>

      {/* Key Features Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="text-center p-6 bg-mint-cream rounded-lg shadow-md">
              <FaDog className="w-12 h-12 mx-auto mb-4 text-risd-blue" />
              <h3 className="text-xl font-semibold mb-2">
                Responsible Breeding
              </h3>
              <p className="text-gray-600">
                Find safe and ethical breeding partners for your pet
              </p>
            </div>
            <div className="text-center p-6 bg-mint-cream rounded-lg shadow-md">
              <FaHeart className="w-12 h-12 mx-auto mb-4 text-risd-blue" />
              <h3 className="text-xl font-semibold mb-2">Pet Care Services</h3>
              <p className="text-gray-600">
                Book reliable grooming, veterinary, and boarding services
              </p>
            </div>
            <div className="text-center p-6 bg-mint-cream rounded-lg shadow-md">
              <FaUsers className="w-12 h-12 mx-auto mb-4 text-risd-blue" />
              <h3 className="text-xl font-semibold mb-2">
                Community Engagement
              </h3>
              <p className="text-gray-600">
                Join a thriving pet owner community via our WhatsApp groups
              </p>
            </div>
            <div className="text-center p-6 bg-mint-cream rounded-lg shadow-md">
              <FaComments className="w-12 h-12 mx-auto mb-4 text-risd-blue" />
              <h3 className="text-xl font-semibold mb-2">Expert Advice</h3>
              <p className="text-gray-600">
                Access trustworthy information to care for your pets better
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-20 bg-mint-cream">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-['Brodien_Bold'] text-risd-blue text-center mb-16">
            How LEASH Works
          </h2>
          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            {[
              {
                title: "Sign Up",
                desc: "Join our platform or WhatsApp groups",
              },
              {
                title: "Browse Services",
                desc: "Explore trusted providers in your area",
              },
              {
                title: "Connect & Engage",
                desc: "Interact with fellow pet enthusiasts and experts",
              },
              {
                title: "Book & Enjoy",
                desc: "Schedule services or find ethical breeding partners",
              },
            ].map((step, index) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 bg-risd-blue text-white rounded-full flex items-center justify-center mx-auto mb-4 text-2xl font-bold">
                  {index + 1}
                </div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.desc}</p>
              </div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 bg-risd-blue text-white">
        <div className="container mx-auto px-6">
          <motion.div
            className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div>
              <h3 className="text-4xl font-bold mb-2">500+</h3>
              <p className="text-xl opacity-90">Happy Pet Owners Connected</p>
            </div>
            <div>
              <h3 className="text-4xl font-bold mb-2">100+</h3>
              <p className="text-xl opacity-90">Successful Breeding Matches</p>
            </div>
            <div>
              <h3 className="text-4xl font-bold mb-2">10,000+</h3>
              <p className="text-xl opacity-90">Services Booked</p>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Why Choose Section */}
      <section className="py-20 bg-mint-cream">
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h2 className="text-4xl font-['Brodien_Bold'] text-risd-blue mb-8 text-center">
              Why LEASH?
            </h2>
            <div className="space-y-4 text-center">
              {[
                "First of its kind in Egypt.",
                "Focus on ethical practices and expert insights.",
                "Building a supportive pet owner network.",
              ].map((point, index) => (
                <div key={index} className="p-4 bg-white rounded-lg shadow-sm">
                  <p className="text-gray-700 text-center">{point}</p>
                </div>
              ))}
            </div>
          </motion.div>
          <img
            src="/images/Graphical elements/Graphical Elements-29.png"
            alt="illustration"
            className="absolute right-10 top-0 w-[350px]"
          />
          <img
            src="/images/Graphical elements/Graphical Elements-47.png"
            alt="illustration"
            className="absolute left-10 top-0 w-[350px]"
          />
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-mint-cream">
        <div className="container mx-auto px-6 text-center">
          <motion.div
            className="space-y-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl font-['Brodien_Bold'] text-risd-blue mb-8">
              Ready to Get Started?
            </h2>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Link
                to="/contact"
                className="inline-block px-8 py-4 bg-risd-blue text-white rounded-lg hover:bg-risd-blue-dark transition-colors"
              >
                Join the LEASH Community
              </Link>
              <Link
                to="/services"
                className="inline-block px-8 py-4 bg-white text-risd-blue rounded-lg hover:bg-gray-100 transition-colors"
              >
                Explore Services
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Home;
